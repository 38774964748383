import React from "react"
import { Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import BlogCard from "../components/BlogCard/BlogCard"
import SEO from "../components/seo"
import SendgridAddContact from "../components/SendgridAddContact/SendgridAddContact"

const Blog = props => {
  const postList = props.data.allContentfulBlogPost.edges
  // const tagList = buildTagList(props.data.allContentfulBlogPost.edges)
  // const [tagFilters, updateTagFilters] = useState(tagList)

  // const filteredPostList = postList.filter(({ node }) => {
  //   if (!tagFilters.size) return true
  //   return node.tags.reduce((cumulative, tag) => {
  //     return cumulative || tagFilters.has(tag)
  //   }, false)
  // })

  return (
    <Layout>
      <SEO title="Blog" />
      <div>
        <div className="page-header">
          <Container style={{ padding: "40px" }}>
            <h1>GridFabric's Blog</h1>
            <p>
              Periodic updates on the load shifting industry, OpenADR and the
              solutions
            </p>
          </Container>
        </div>
      </div>

      <Container style={{ padding: "50px 15px", maxWidth: "1000px" }}>
        <Row>
          {postList.length ? (
            postList.map(({ node }, i) => <BlogCard post={node} key={i} />)
          ) : (
            <div>sorry none found!</div>
          )}
        </Row>
      </Container>
      <SendgridAddContact listName="newsletter" />
    </Layout>
  )
}

export default Blog

export const blogQuery = graphql`
  query blogQuery {
    allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          slug
          heroImage {
            fluid(maxWidth: 786) {
              ...GatsbyContentfulFluid
            }
          }
          publishDate
          title
          tags
          description {
            description
          }
          body {
            childMarkdownRemark {
              timeToRead
            }
          }
        }
      }
    }
  }
`

// function buildTagList(postList) {
//   return postList.reduce((set, { node }) => {
//     return node.tags.reduce((updatedSet, tag) => updatedSet.add(tag), set)
//   }, new Set())
// }
