import React from 'react'
import moment from 'moment'

import {Link} from 'gatsby'
import {Badge, Row, Col} from 'react-bootstrap'
import Img from "gatsby-image"
import {clickEvent} from "../../utils/events"

import styles from './BlogCard.module.css'

const BlogCard = ({ post }) => (
  <Link
    to={`/blog/${post.slug}`}
    className={styles.card}
    onClick={() => clickEvent("Blog Page", "See Blog Post", post.slug)}
  >
    <Row className={styles.cardBody}>
      <Col className="d-none d-md-block" style={{ maxWidth: "300px" }}>
        <div className={styles.image}>
          <Img fluid={post.heroImage.fluid} style={{ height: "100%" }} alt="" />
        </div>
      </Col>
      <Col className={styles.cardContent}>
        <h4 className={styles.title}>{post.title}</h4>
        <p className={styles.excerpt}>{post.description.description}</p>
        <div className={styles.tags}>
          {post.tags && post.tags.length
            ? post.tags.map((tag, i) => (
                <Badge
                  variant="info"
                  pill
                  className="badge-gridfabric"
                  style={{ margin: "5px", padding: "5px" }}
                  key={i}
                >
                  {tag}
                </Badge>
              ))
            : null}
        </div>
        <div className={styles.footer}>
          {post.body && `${post.body.childMarkdownRemark.timeToRead} minute read ∙ `}
          {moment(post.publishDate, "YYYY-MM-DD").format("MMM Do, YYYY")}
        </div>
      </Col>
    </Row>
  </Link>
)

export default BlogCard